export const permission = [
  {
    type: "owner",
    settings: {
      view: true,
      chartofaccounts: true,
      usermanagement: true,
      subscription: true,
      erpconnect: true,
    },
    userModule: true,
    apModule: {
      transactionAddEdit: true,
      invoiceApproveReject: true,
      submitInvoice: true,
    },
    arModule: {
      addEditTransactions: true,
    },
    bankingModule: {
      addEditTransactions: true,
      view: true,
    },
    apModuleView: {
      invoice: true,
      recurring: true,
    },
    arModuleView: {
      invoice: true,
      recurring: true,
    },
    reports: true,
    remarks: "Owner can add/remove admins",
  },
  {
    type: "admin",
    settings: {
      view: true,
      chartofaccounts: true,
      usermanagement: true,
      subscription: true,
      erpconnect: true,
    },
    userModule: true,
    apModule: {
      transactionAddEdit: true,
      invoiceApproveReject: true,
      submitInvoice: true,
      sendToErp: true,
    },
    arModule: {
      addEditTransactions: true,
    },
    bankingModule: {
      addEditTransactions: true,
      view: true,
    },
    apModuleView: {
      invoice: true,
      recurring: true,
    },
    arModuleView: {
      invoice: true,
      recurring: true,
    },
    reports: true,
    remarks: "Admin can add/remove non-admins",
  },
  {
    type: "accountant",
    settings: {
      view: true,
      chartofaccounts: true,
      usermanagement: false,
      subscription: false,
      erpconnect: false,
    },
    userModule: false,
    apModule: {
      transactionAddEdit: true,
      invoiceApproveReject: true,
      submitInvoice: true,
    },
    arModule: {
      addEditTransactions: true,
    },
    bankingModule: {
      addEditTransactions: true,
      view: true,
    },
    apModuleView: {
      invoice: true,
      recurring: true,
    },
    arModuleView: {
      invoice: true,
      recurring: true,
    },
    reports: true,
  },
  {
    type: "expense_manager",
    settings: {
      view: false,
      chartofaccounts: false,
      usermanagement: false,
      subscription: false,
      erpconnect: false,
    },
    userModule: false,
    apModule: {
      transactionAddEdit: true,
      invoiceApproveReject: true,
      submitInvoice: true,
      sendToErp: false,
    },
    arModule: {
      addEditTransactions: false,
    },
    bankingModule: {
      addEditTransactions: false,
      view: false,
    },
    apModuleView: {
      invoice: true,
      recurring: false,
    },
    arModuleView: {
      invoice: false,
      recurring: false,
    },
    reports: false,
  },
  {
    type: "mis_reporter",
    settings: {
      view: false,
      chartofaccounts: false,
      usermanagement: false,
      subscription: false,
      erpconnect: false,
    },
    userModule: false,
    apModule: {
      transactionAddEdit: false,
      invoiceApproveReject: true,
      submitInvoice: false,
    },
    arModule: {
      addEditTransactions: false,
    },
    bankingModule: {
      addEditTransactions: false,
      view: false,
    },
    apModuleView: {
      invoice: true,
      recurring: true,
    },
    arModuleView: {
      invoice: true,
      recurring: true,
    },
    reports: true,
  },
  {
    type: "standard_user",
    settings: {
      view: false,
      chartofaccounts: false,
      usermanagement: false,
      subscription: false,
      erpconnect: false,
    },
    userModule: false,
    apModule: {
      transactionAddEdit: false,
      invoiceApproveReject: false,
      submitInvoice: true,
    },
    arModule: {
      addEditTransactions: false,
    },
    bankingModule: {
      addEditTransactions: false,
      view: false,
    },
    apModuleView: {
      invoice: true,
      recurring: true,
    },
    arModuleView: {
      invoice: true,
      recurring: true,
    },
    reports: true,
  },
  {
    type: "auditor",
    settings: {
      view: false,
      chartofaccounts: false,
      usermanagement: false,
      subscription: false,
      erpconnect: false,
    },
    userModule: false,
    apModule: {
      transactionAddEdit: true,
      invoiceApproveReject: true,
      submitInvoice: false,
    },
    arModule: {
      addEditTransactions: true,
    },
    bankingModule: {
      addEditTransactions: true,
      view: true,
    },
    apModuleView: {
      invoice: true,
      recurring: true,
    },
    arModuleView: {
      invoice: true,
      recurring: true,
    },
    reports: true,
  },
];
