import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  avatar07,
  logo,
  logoSmall,
  logoSmallWhite,
  // search,
} from "../_components/Imagepath";
import { useDispatch } from "react-redux";
import { signOut } from "../redux/actions/UserAction";
import { useSelector } from "react-redux";

const Header = (props) => {
  const userData = useSelector((state) => state.userSignin);
  const { refresh, userName, permissions } = userData;
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };

  const onMenuClik = () => {
    // eslint-disable-next-line react/prop-types
    props.onMenuClick();
  };

  useEffect(() => {
    const handleClick = () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    };

    const maximizeBtn = document.querySelector(".win-maximize");
    maximizeBtn.addEventListener("click", handleClick);

    return () => {
      maximizeBtn.removeEventListener("click", handleClick);
    };
  }, []);

  const logoutHandler = () => {
    dispatch(signOut(refresh));
  };

  return (
    <div className="header header-one">
      <Link
        to="index"
        className="d-inline-flex d-sm-inline-flex align-items-center d-md-inline-flex d-lg-none align-items-center device-logo"
      >
        <img src={logo} className="img-fluid logo2" alt="Logo" />
      </Link>
      <div className="main-logo d-inline float-start d-lg-flex align-items-center d-none d-sm-none d-md-none">
        <div className="logo-white">
          <Link to="index">
            <img src={logo} className="img-fluid logo-blue" alt="Logo" />
            {/* GlanceWise */}
          </Link>
          <Link to="index">
            <img
              src={logoSmallWhite}
              className="img-fluid logo-small"
              alt="Logo"
            />
          </Link>
        </div>
        <div className="logo-color">
          <Link to="index">
            <img src={logo} className="img-fluid logo-blue" alt="Logo" />
          </Link>
          <Link to="index">
            <img src={logoSmall} className="img-fluid logo-small" alt="Logo" />
          </Link>
        </div>
      </div>
      {/* Sidebar Toggle */}
      <Link to="#" id="toggle_btn" onClick={handlesidebar}>
        <span className="toggle-bars">
          <span className="bar-icons" />
          <span className="bar-icons" />
          <span className="bar-icons" />
          <span className="bar-icons" />
        </span>
      </Link>

      <Link
        to="#"
        className="mobile_btn"
        id="mobile_btn"
        onClick={() => onMenuClik()}
      >
        <i className="fas fa-bars" />
      </Link>
      {/* /Mobile Menu Toggle */}
      {/* Header Menu */}
      <ul className="nav nav-tabs user-menu">
        <li className="nav-item  has-arrow dropdown-heads ">
          <Link to="#" className="win-maximize">
            <i className="fe fe-maximize" />
          </Link>
        </li>
        {/* User Menu */}
        <li className="nav-item dropdown profile-dropdown">
          <Link
            to="#"
            className="user-link  nav-link"
            // data-bs-toggle="dropdown"
            onClick={() => setShow(!show)}
          >
            <span className="user-img">
              <img src={avatar07} alt="img" className="profilesidebar" />
              <span className="animate-circle" />
            </span>
            <span className="user-content">
              <span className="user-details">{userName}</span>
              <span className="user-name">Glancewise</span>
            </span>
          </Link>
          {show && (
            <div className="dropdown-menu menu-drop-user">
              <div className="profilemenu">
                <div className="subscription-menu">
                  <ul>
                    <li>
                      <Link className="dropdown-item" to="/profile">
                        Profile
                      </Link>
                    </li>
                    {permissions?.settings?.view && (
                      <li>
                        <Link className="dropdown-item" to="/settings">
                          Settings
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="subscription-logout">
                  <ul>
                    <li className="pb-0" onClick={logoutHandler}>
                      <Link className="dropdown-item" to="#">
                        Log Out
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </li>
        {/* /User Menu */}
      </ul>
      {/* /Header Menu */}
    </div>
  );
};
export default Header;
