/* eslint-disable */

import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { register } from "../../redux/actions/UserAction";
import { Spin, message } from "antd";
import { Modal } from "bootstrap";
import OTP from "./otp";
import { USER_REGISTER_RESET } from "../../redux/constants/UserConstant";

const schema = yup.object().shape({
  username: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Invalid email")
    .required("Email is required"),
  phone: yup
    .string()
    // .matches(/^[0-9]{12}$/, "Mobile number must be 10 digits")
    .required("Mobile number is required"),
  password: yup
    .string()
    .min(4, "Password must be at least 4 characters")
    .required("Password is required"),
});

const Step1Form = ({ setFormData, formData, setCurrentStep }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const userRegisterData = useSelector((state) => state.userRegister);
  const { userRegister, success, loading, error } = userRegisterData;
  const dispatch = useDispatch();
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [apiError, setApiError] = useState();

  const [messageApi, contextHolder] = message.useMessage();
  const onSubmit = (data) => {
    setFormData((prevData) => ({ ...prevData, ...data }));
    dispatch(register(data));
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setApiError((prevState) => ({
      ...prevState,
      [id]: "",
    }));
  };

  useEffect(() => {
    if (success) {
      const otpModal = new Modal(document.getElementById("otp-modal"));
      otpModal.show();
    }
  }, [success]);

  useEffect(() => {
    setApiError(error);
  }, [error]);

  useEffect(() => {
    return () => {
      dispatch({ type: USER_REGISTER_RESET });
    };
  }, [dispatch]);

  return (
    <>
      {contextHolder}
      <h5>Enter user details</h5>
      <br />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-block mb-3 input_text">
          <div className="col-lg-12">
            <Controller
              name="username"
              control={control}
              render={({ field: { value, onChange } }) => (
                <input
                  type="text"
                  className={`form-control ${
                    errors.username ? "is-invalid" : ""
                  }`}
                  id="username"
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    handleChange(e);
                  }}
                  placeholder="username"
                />
              )}
            />
            {errors.username?.message && (
              <p className="text-danger">{errors.username?.message}</p>
            )}
            {apiError?.username && (
              <p className="text-danger">{apiError?.username}</p>
            )}
          </div>
        </div>

        <div className="input-block mb-3 input_text">
          <div className="col-lg-12">
            <Controller
              name="email"
              control={control}
              render={({ field: { value, onChange } }) => (
                <input
                  type="email"
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                  placeholder="Email"
                  onChange={(e) => {
                    onChange(e);
                    handleChange(e);
                  }}
                  id="email"
                  value={value}
                />
              )}
            />
            {errors.email?.message && (
              <p className="text-danger">{errors.email?.message}</p>
            )}
            {apiError?.email && (
              <p className="text-danger">{apiError?.email}</p>
            )}
          </div>
        </div>

        <div className="input-block mb-3 input_text">
          <div className="col-lg-12">
            <Controller
              name="phone"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className="input-group">
                  <input
                    type="tel"
                    className={`form-control ${
                      errors.phone ? "is-invalid" : ""
                    }`}
                    placeholder="Mobile Number"
                    onChange={(e) => {
                      let inputValue = e.target.value;

                      // Remove any non-numeric characters except for the initial +91
                      inputValue = inputValue.replace(/[^0-9]/g, "");

                      // Always prepend +91 to the input
                      if (inputValue.length >= 2) {
                        inputValue = "+91" + inputValue.substring(2);
                      } else {
                        inputValue = "+91"; // Ensure +91 is always there
                      }

                      onChange(inputValue);
                      handleChange(e); // Your custom handleChange function
                    }}
                    value={value && value.length >= 3 ? value : "+91"} // Ensure +91 is there on initial load
                    id="phone"
                  />
                </div>
              )}
            />

            {errors.phone?.message && (
              <p className="text-danger">{errors.phone?.message}</p>
            )}
            {apiError?.phone && (
              <p className="text-danger">{apiError?.phone}</p>
            )}
          </div>
        </div>

        <div className="input-block mb-3 input_text">
          <div className="col-lg-12">
            <Controller
              name="password"
              control={control}
              render={({ field: { value, onChange } }) => (
                <input
                  type="password"
                  className={`form-control ${
                    errors.password ? "is-invalid" : ""
                  }`}
                  placeholder="Password"
                  onChange={(e) => {
                    onChange(e);
                    handleChange(e);
                  }}
                  value={value}
                  id="password"
                />
              )}
            />
            {errors.password?.message && (
              <p className="text-danger">{errors.password?.message}</p>
            )}
            {apiError?.password && (
              <p className="text-danger">{apiError?.password}</p>
            )}
          </div>
        </div>

        {/* <div className="mb-3">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="customCheck2"
              required
            />
            <label className="form-check-label" htmlFor="customCheck2">
              Terms and conditions
            </label>
          </div>
        </div> */}
        <div className="text-center">
          <button
            className="btn btn-lg btn-block w-100 btn-primary"
            type="submit"
          >
            {loading ? <Spin /> : "Continue"}
          </button>
        </div>
      </form>

      <OTP
        show={showOtpModal}
        onClose={() => setShowOtpModal(false)}
        phone={formData?.phone}
        setCurrentStep={setCurrentStep}
      />
    </>
  );
};

Step1Form.propTypes = {
  setCurrentStep: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
};

export default Step1Form;
