import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import "../_components/antd.css";
import Usermanagement from "../assets/img/user-management.png";
import Subscription from "../assets/img/subscription.png";
import Accounts from "../assets/img/accountant.png";
import Connect from "../assets/img/connectivity.png";
import { useSelector } from "react-redux";

const Settings = () => {
  const [menu, setMenu] = useState(false);
  const userData = useSelector((state) => state.userSignin);
  const { permissions } = userData;

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const cardStyle = {
    margin: "5%", // Adjust as needed
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "10px",
    width: "18.5%",
    display: "inline-block",
    textAlign: "center",
    marginRight: "0px",
    paddingBottom: "20px",
    background: "#f0f0f0",
  };

  const imageStyle = {
    width: "100px", // Adjust width as needed
    height: "100px", // Adjust height as needed
    borderRadius: "8px", // Optional: add border radius
    marginTop: "20px",
  };

  const location = useLocation();

  useEffect(() => {
    if (!location?.pathname.includes("/chartofaccount")) {
      localStorage.removeItem("listCurrentTab");
    }
  }, [location.pathname]);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper customers">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="content-page-header">
                <div className="col">
                  <h5>Settings</h5>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card customers">
                  <div className="card-body settingsIcons">
                    {permissions?.settings?.usermanagement && (
                      <Link to="/user-management" style={cardStyle}>
                        <img src={Usermanagement} alt="" style={imageStyle} />
                        <p>User Management</p>
                      </Link>
                    )}

                    {permissions?.settings?.subscription && (
                      <Link to="/membership-plans" style={cardStyle}>
                        <img src={Subscription} alt="" style={imageStyle} />
                        <p>Subscription</p>
                      </Link>
                    )}

                    {permissions?.settings?.chartofaccounts && (
                      <Link to="/chartofaccount" style={cardStyle}>
                        <img src={Accounts} alt="Image 3" style={imageStyle} />
                        <p>Chart of Accounts</p>
                      </Link>
                    )}

                    {permissions?.settings?.erpconnect && (
                      <Link to="/crm-erp" style={cardStyle}>
                        <img src={Connect} alt="Image 4" style={imageStyle} />
                        <p>CRM/ERP Connect</p>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* sample modal content */}
        </div>
      </div>
    </>
  );
};
export default Settings;
