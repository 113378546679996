import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory, Route, Redirect } from "react-router-dom"; // Import useHistory

// eslint-disable-next-line react/prop-types
function PrivateRoute({component: Component,defaultPermissions,moduleName,subModuleName,...rest}) {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo, permissions } = userSignin;
  const history = useHistory(); // Use useHistory hook

  // Function to handle redirection if user is not signed in
  const redirectToSignIn = () => {
    history.push("/"); // Use history.push for redirection
  };

  const moduleNamePermission = useMemo(() => {
    return (
      (permissions &&
        permissions[moduleName] &&
        permissions[moduleName] === true) ||
      (subModuleName && permissions[moduleName] &&
        permissions[moduleName][subModuleName] &&
        permissions[moduleName][subModuleName] === true) ||
      (defaultPermissions && defaultPermissions === true)
    );
  }, [moduleName, defaultPermissions]);

  // Paths that should be accessible without authentication
  const publicPaths = ["/forgot-password", "/reset-password"];
  // Check if current path is a public path
  const isPublicPath = publicPaths.some((path) =>
    rest.location.pathname.startsWith(path)
  );
  return (
    <>
      <Route
        {...rest}
        render={
          (props) =>
            userInfo || isPublicPath ? (
              moduleName || defaultPermissions ? (
                moduleNamePermission ? (
                  <Component {...props} />
                ) : (
                  <Redirect to={"/index"} />
                )
              ) : null
            ) : (
              redirectToSignIn()
            ) // Call redirectToSignIn function here
        }
      />
    </>
  );
}

export default PrivateRoute;
